@font-face {
  font-family: "Modernline";
  src: url("../public/fonts/modernline-webfont.woff2");
}

/* Chris and Constance colors
$DARK_BLUE_TEXT: #003153;
$TERRACOTTA: #b85657;
$PEACH: #c69ea1;
$CREAM_BACKGROUND: #f7f6f5;
*/

/*
$OFF_WHITE: #FFF5E0;
$LIGHT_RED: #FF6969;
$DARK_RED: #C70039;
$DARK_BLUE: #141E46;
*/

$OFF_WHITE: #F0ECE5;
$LIGHT_GRAY: #B6BBC4;
$MED_BLUE: #31304D;
$DARK_BLUE: #161A30;

$BACKGROUND_COLOR: $OFF_WHITE;
$ACCENT_COLOR: $MED_BLUE;
$TEXT_COLOR: $DARK_BLUE;

$theme-colors: (
  "primary": $ACCENT_COLOR,
  "secondary": $ACCENT_COLOR,
);

/* used by reboot.css */
$body-bg: $BACKGROUND_COLOR;
$body-color: $TEXT_COLOR;
$font-family-sans-serif: "Marcellus";

body {
  margin: 0;
  font-family: "Marcellus";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h2 {
  font-weight: 200;
}

h3 {
  font-weight: 200;
  font-size: 16;
}

p {
  font-weight: 100;
  text-align: left;
}

// Import Bootstrap and its default variables.
@import "~bootstrap/scss/bootstrap.scss";

/** Override react bootstrap component behavior. */

/** Remove caret from dropdown menus. */
.dropdown-toggle::after {
  display: none;
}

/** Remove border on menu hamburger button. */
.navbar-toggler {
  border: none;
  box-shadow: none !important;
}

/** Registry button stuff. */
.registry-button:hover {
  box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.2);
}

.charity-button:hover {
  opacity: .8;
}
